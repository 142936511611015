import Vue from 'vue'
import VueRouter from 'vue-router'

import settings from "@/router/settings";

import VueMeta from 'vue-meta'


Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    // Logged out views
    {
        path: '/',
        name: 'Home',
        component: () =>
            import( /* webpackChunkName: "home-page" */ '../views/pages/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import( /* webpackChunkName: "login" */ '../views/auth/Login.vue')
    },
    {
        path: '/login/:token',
        name: 'LoginSplash',
        component: () =>
            import( /* webpackChunkName: "login-splash" */ '../views/auth/LoginSplash.vue')
    },

    // Logged in views
    {
        path: '/dashboard',
        name: 'DashboardView',
        component: () =>
            import( /* webpackChunkName: "dashboard-view" */ '../views/areas/dashboard/Index.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/appointments',
        name: 'AppointmentsIndex',
        component: () =>
            import( /* webpackChunkName: "appointments-index" */ '../views/areas/appointments/Index.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/appointments/create',
        name: 'AppointmentsCreate',
        component: () =>
            import( /* webpackChunkName: "appointments-create" */ '../views/areas/appointments/Create.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/consultations/:id',
        name: 'ConsultationsView',
        component: () =>
            import( /* webpackChunkName: "consultations-view" */ '../views/areas/consultations/View.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/agreements',
        name: 'AgreementsIndex',
        component: () =>
            import( /* webpackChunkName: "agreements-index" */ '../views/areas/agreements/Index.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/agreements/:id',
        name: 'AgreementsView',
        component: () =>
            import( /* webpackChunkName: "agreements-view" */ '../views/areas/agreements/View.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/allergies',
        name: 'AllergiesIndex',
        component: () =>
            import( /* webpackChunkName: "allergies-index" */ '../views/areas/allergies/Index.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/files',
        name: 'FilesIndex',
        component: () =>
            import( /* webpackChunkName: "files-index" */ '../views/areas/files/Index.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/forms',
        name: 'FormsIndex',
        component: () =>
            import( /* webpackChunkName: "forms-index" */ '../views/areas/forms/Index.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/forms/response/:id',
        name: 'FormsResponseView',
        component: () =>
            import( /* webpackChunkName: "forms-response-view" */ '../views/areas/forms/Response.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/health-diary',
        name: 'HealthDiaryIndex',
        component: () =>
            import( /* webpackChunkName: "health-diary-index" */ '../views/areas/health-diary/Index.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/health-planner',
        name: 'HealthPlannerIndex',
        component: () =>
            import( /* webpackChunkName: "health-planner-index" */ '../views/areas/health-planner/Index.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/health-planner/:id',
        name: 'HealthPlannerView',
        component: () =>
            import( /* webpackChunkName: "health-planner-view" */ '../views/areas/health-planner/View.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/tracker',
        name: 'HealthTrackerIndex',
        component: () =>
            import( /* webpackChunkName: "mymop-index" */ '../views/areas/mymop/Index.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/tracker/plan/:id',
        name: 'HealthTrackerPlanView',
        component: () =>
            import( /* webpackChunkName: "mymop-plan-view" */ '../views/areas/mymop/View.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/tracker/request/:id',
        name: 'HealthTrackerRequestView',
        component: () =>
            import( /* webpackChunkName: "mymop-request-view" */ '../views/areas/mymop/Request.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/online-programmes',
        name: 'OnlineProgrammesIndex',
        component: () =>
            import( /* webpackChunkName: "online-programmes-index" */ '../views/areas/online-programmes/Index.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/online-programmes/:id',
        name: 'OnlineProgrammesView',
        component: () =>
            import( /* webpackChunkName: "online-programmes-view" */ '../views/areas/online-programmes/View.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/online-programme-items/:id',
        name: 'OnlineProgrammeItemView',
        component: () =>
            import( /* webpackChunkName: "online-programme-item-view" */ '../views/areas/online-programmes/ItemView.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/orders',
        name: 'OrdersIndex',
        component: () =>
            import( /* webpackChunkName: "orders-index" */ '../views/areas/orders/Index.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/orders/:id/card-payment',
        name: 'OrdersPayment',
        component: () =>
            import( /* webpackChunkName: "orders-payment" */ '../views/areas/orders/Payment.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/orders/:id/invoice',
        name: 'OrdersInvoice',
        component: () =>
            import( /* webpackChunkName: "orders-invoice" */ '../views/areas/orders/Invoice.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/orders/:id',
        name: 'OrdersView',
        component: () =>
            import( /* webpackChunkName: "orders-view" */ '../views/areas/orders/View.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/system-check',
        name: 'SystemCheck',
        component: () =>
            import( /* webpackChunkName: "system-check" */ '../views/areas/consultations/Preflight.vue'),
    },

    {
        path: '/resources',
        name: 'ResourcesIndex',
        component: () =>
            import( /* webpackChunkName: "resources-index" */ '../views/areas/resources/Index.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/resources/:id',
        name: 'ResourcesView',
        component: () =>
            import( /* webpackChunkName: "resources-view" */ '../views/areas/resources/View.vue'),
        meta: {
            auth: true
        },
    },

    // Settings
    ...settings,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "text-dark",
    linkExactActiveClass: "text-dark",
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')
    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }

    if (to.path == '/login' || to.path == '/') {
        if (loggedIn) {
            next('/dashboard')
        }
    }
    next()

})

export default router