export default [
    {
      path: '/settings',
      name: 'SettingsIndex',
      component: () => import( /* webpackChunkName: "settings-index" */ '../views/areas/settings/Index.vue'),
      meta: {
        auth: true
      },
    },
    {
        path: '/settings/details',
        name: 'SettingsDetailsView',
        component: () => import( /* webpackChunkName: "settings-details-view" */ '../views/areas/settings/Details.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/settings/notifications',
        name: 'SettingsNotificationsView',
        component: () => import( /* webpackChunkName: "settings-notifications-view" */ '../views/areas/settings/Notifications.vue'),
        meta: {
            auth: true
        },
    },
    {
        path: '/settings/email',
        name: 'SettingsChangeEmail',
        component: () => import( /* webpackChunkName: "settings-change-email" */ '../views/areas/settings/ChangeEmail.vue'),
        meta: {
            auth: true
        },
    },
];
