import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

window._ = require('lodash');

import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "@/assets/styles/app.scss";


import moment from "moment";
window.moment = moment;
require('moment-timezone');
moment.locale('en-GB');
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

import "./mixins/methods";

/*
vue-json-csv : https://github.com/Belphemur/vue-json-csv
*/

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
    hideModules: {
        "table": true
    },
});



Vue.config.productionTip = false
import axios from "axios"
Vue.prototype.$axios = axios

Vue.prototype.$axios.defaults.headers.common['Content-Type'] = 'application/json'
Vue.prototype.$axios.defaults.headers.common['Accept'] = 'application/json'

// require("./validationRules");


// Validation Rules

import {
    extend
} from 'vee-validate';
import {
    required,
    email,
    min,
    url
} from 'vee-validate/dist/rules';

import PhoneNumber from 'awesome-phonenumber'

extend('phone', {
    message(fieldName) {
        return `Please enter a valid phone number`;
    },
    validate(value) {
        return new Promise(resolve => {
            let phone = new PhoneNumber(value);
            resolve({
                valid: phone.isValid()
            })
        });
    }
});

// Add the required rule
extend('required', {
    ...required,
    message: 'This field is required'
});

extend('email', {
    ...email,
    message: 'Please provide a valid email address'
});

extend('min', {
    ...min,
    message: 'Input not long enough'
});

import isURL from 'validator/lib/isURL';

extend('url', {
    message(fieldName) {
        return `The URL is not valid eg. https://swandoola.com`;
    },
    validate(value) {
        return isURL(value, {
            require_tld: true
        });
    }
});

extend('email_unique', {
    message(fieldName) {
        return `Email address already in use.`;
    },
    async validate(value) {
        const response = await fetch('/clients/check-email/' + value);
        const responseJson = await response.json();
        return responseJson.valid === "true";
    }
});

import {
    ValidationProvider
} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);

// End validation rules

import VueSnackbar from 'vue-snack'
import 'vue-snack/dist/vue-snack.min.css'


import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

Vue.use(VueSnackbar, {
    position: 'bottom-right',
    time: 3000
})


import EventBus from "./eventBus";
Vue.prototype.$EventBus = EventBus;

Vue.component('text-editor-modal', require('./views/globals/TextEditorModal.vue').default);
Vue.component('phone-number-input', require('./views/globals/PhoneNumberInput.vue').default);
Vue.component('browser-warning', require('./views/globals/BrowserWarning.vue').default);
Vue.component('date-input', require('./views/globals/DateInput.vue').default);
Vue.component('timezone-check', require('./views/layouts/TimezoneCheck.vue').default);


import VueStash from 'vue-stash';
import store from './store/store'

Vue.use(VueStash)




// Service worker for self-updating cache
import './registerServiceWorker';

new Vue({
    router,
    data: {
        store
    },

    created() {

        const userInfo = localStorage.getItem('user')
        if (userInfo) {
            const userData = JSON.parse(userInfo)

            this.$store.user = userData.user;
            this.$store.token = userData.token;
            this.$axios.defaults.headers.Authorization = `Bearer ${this.$store.token.access_token}`;
            // this.$store.commit('setUserData', userData)
        }

        this.$axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('user')
                        // this.$store.dispatch('logout')

                    if (localStorage.getItem("googleLogin")) {
                        var auth2 = gapi.auth2.getAuthInstance();
                        auth2.signOut().then(function() {
                            console.log("User signed out.");
                        });
                    }
                    localStorage.removeItem("googleLogin");

                    location.href = '/';
                }
                return Promise.reject(error)
            }
        )
    },
    mounted() {

    },
    render: h => h(App)
}).$mount('#app')
