import Vue from "vue"

//remove underscores & capitalize
Vue.mixin({
    methods: {
        translateTime(time, client){
            console.log(time);
            console.log(client);
            const clinicTz = client.clinic.timezone;
            //date doesnt matter but want to use moment for TZ convert
            //convert time from clinic TZ to local TZ.
            time = moment.tz("1999-01-01 " + time, clinicTz).local().format("HH:mm");
            if (time >= "12:00") {
                return time + "pm";
            } else {
                return time + "am";
            }
        }
    }
})
