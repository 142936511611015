<template>
  <li class="nav-item my-auto dropdown ml-4 mr-auto" v-if="user">
    <a id="navbarSupportDropdown"
       href="#"
       role="button"
       data-toggle="dropdown"
       aria-haspopup="true"
       aria-expanded="false"
    >
      <button class="btn btn-light btn-sm" v-tooltip.bottom="'Support'">
        <i class="fad fa-question"></i>
      </button>
    </a>

    <div class="dropdown-menu dropdown-menu-right mt-2 py-0 shadow"
         style="z-index: 1021; min-width: 25rem; max-width: 25rem;"
         aria-labelledby="navbarSupportDropdown">

      <div class="bg-success pt-2">
        <div class="row mx-0">
          <div class="col">
            <div class="">
              <input type="text"
                     v-model="faqSearch"
                     @changed="searchFaqs"
                     class="form-control mb-2"
                     placeholder="Search our Frequently Asked Questions..."
              />
            </div>
          </div>
        </div>
      </div>

      <div class="bg-light pt-2">
        <div class="row mx-2">
          <div class="col">
            <div class="row">
              <div class="col">
                <b class="pl-1" v-if="faqSearch.length < 4">Popular Articles</b>
                <b class="pl-1" v-else>Search Results</b>

                <div v-if="loading" class="row">
                  <div class="col text-center">
                    <i class="fad fa-spin fa-spinner fa-3x text-primary"></i>
                  </div>
                </div>

                <ul class="ml-4 mt-1">
                  <li v-for="faq in searchResults.slice(0, 8)" class="mb-2">
                    <a :href="'https://support.swandoola.com/faqs-client/' + faq.slug"
                       class="d-block text-primary text-nowrap overflow-hidden small"
                       style="text-overflow: ellipsis">
                      {{faq.title}}
                    </a>
                  </li>
                </ul>
                <div class="row" v-if="searchResults.length === 0 && !loading">
                  <div class="col text-center">
                    <p>No articles found for those search terms.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="dropdown-divider my-0"></div>
        <a class="dropdown-item py-2"
           href="https://support.swandoola.com/faqs-client"
           target="_blank">
          View all FAQs
        </a>
        <div class="dropdown-divider my-0"></div>
        <a class="dropdown-item py-2"
           href="#"
           @click="showSupportRequestModal">
          Open a Support Request
        </a>
      </div>
    </div>
  </li>
</template>

<script>
import debounce from "debounce";
export default {
  props: ["user"],

  data() {
    return {
      loading: true,
      faqSearch: "",
      searchResults: [],
      popularArticles: [],
    }
  },

  computed: {
  },
  methods: {
    fetchPopularArticles() {
      this.loading = true;
      this.$axios
          .get(process.env.VUE_APP_API_URL + "/support/faqs/popular")
          .then(({ data }) => {
            this.popularArticles = data;
            this.searchResults = this.popularArticles;
            this.loading = false;
          });
    },
    searchFaqs: debounce( function() {
      this.searchResults = [];
      this.loading = true;
      this.$axios
          .post(process.env.VUE_APP_API_URL + "/support/faqs/search", {search: this.faqSearch})
          .then(({ data }) => {
            this.searchResults = data;
            this.loading = false;
          });
    }),
    showSupportRequestModal() {
      this.$emit("showSupportRequestModal");
    }
  },
  mounted() {
    this.fetchPopularArticles();
  },
  watch: {
    faqSearch(newVal, oldVal) {
      if (newVal.length > 3){
        this.searchFaqs();
      } else {
        this.searchResults = this.popularArticles;
      }
    }
  },
  filters: {
  }
};
</script>