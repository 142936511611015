<template>
    <div>
        <div v-if="!isChrome && !isFirefox" class="bg-danger fixed-bottom">
            <div class="row">
                <div class="col py-1">
                    <p class="text-white small px-2"><span class=" font-weight-bold">Warning:</span> Unsupported browser detected ({{name}}). We recommend using <a style="text-decoration: underline" href="https://www.google.com/chrome">Google Chrome</a> for the best experience.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isChrome: this.$browserDetect.isChrome,
                isFirefox: this.$browserDetect.isFirefox,
                name: this.$browserDetect.meta.name,
            };
        },
        methods: {

        },
        mounted() {
        },
    };
</script>

<style>

</style>