<template>
  <div
    class="modal fade"
    id="supportRequestModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="supportRequestModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="supportRequestModalTitle">Support Request</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col">
              <label for>
                Short description
                <span class="text-danger">*</span>
              </label>
              <input type="text" v-model="form.title" class="form-control" autofocus required />
            </div>
            <div class="col">
              <label for>
                Support request type
                <span class="text-danger">*</span>
              </label>
              <select v-model="form.type" class="form-control" required>
                <option value disabled>Select a type</option>
                <option value="bug">Bug</option>
                <option value="feature">Feature</option>
                <option value="request">Request</option>
                <option value="billing">Billing</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label for>
                Detailed Description
                <span class="text-danger">*</span>
              </label>
              <textarea class="form-control" rows="5" required v-model="form.content"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary mr-auto" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="openRequest">Open Support Request</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      form: {
        user_id: this.user.id,
        user_type: "practitioner",
        title: "",
        content: "",
        type: "",
        platform: "practitioner app",
        status: "new"
      }
    };
  },
  methods: {
    openRequest() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/support-requests", this.form).then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        $("#supportRequestModal").modal("hide");
      });
    }
  },
  mounted() {
    $("#supportRequestModal").modal("show");
    $("#supportRequestModal").on("hide.bs.modal", e => {
      this.$emit("close");
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    }
  }
};
</script>

<style>
</style>