export default {
    // Globally available user object
    user: null,
    token: null,
    agreements: null,
    pendingAgreementsCount: null,
    unreadCounters: {
      appointments: 0,
      orders: 0,
      carts: 0,
      resources: 0,
      forms: 0,
      files: 0,
      terms: 0,
      settings: 0,
      mymop: 0,
      health_planner: 0,
    },
}