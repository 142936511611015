<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
          <img
            src="@/assets/images/brand/full-logo.svg"
            alt="Swandoola"
            width="250"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Left Side Of Navbar -->
          <ul class="navbar-nav ml-4 mr-auto text-center"></ul>

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ml-auto my-auto">
            <!-- <li class="nav-item my-auto mr-3">
              <a
                class="nav-btn btn btn-sm btn-light"
                href="https://swandoola.com"
                ><i class="fad fa-globe mr-2"></i>Swandoola Websites</a
              >
            </li>

            <li class="nav-item my-auto mr-3">
              <a
                class="nav-btn btn btn-sm btn-light"
                href="https://edu.swandoola.com/login"
                ><i class="fad fa-university mr-2"></i>Educational Provider
                Login</a
              >
            </li>
            <li class="nav-item my-auto mr-5">
              <a
                class="nav-btn btn btn-sm btn-light"
                href="https://suppliers.swandoola.com/login"
                ><i class="fad fa-truck mr-2"></i>Suppliers Login</a
              >
            </li> -->
            <li class="nav-item my-auto">
              <router-link class="nav-btn btn btn-sm btn-primary" to="/login"
                ><i class="fad fa-sign-in mr-2"></i>Login</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>