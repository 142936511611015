<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
      <div class="container-fluid">
        <router-link class="navbar-brand" :to="user ? '/dashboard' : '/'">
          <img
            src="@/assets/images/brand/navy-gold-med.png"
            alt="Swandoola"
            width="36"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse navbar-toggleable-sm"
          id="navbarSupportedContent"
        >
          <!-- Left Side Of Navbar -->
          <ul class="navbar-nav mr-auto text-center my-auto mt-md-2">
            <li
              class="nav-item my-auto ml-4 mr-4"
              v-if="user"
              v-tooltip.bottom="'Dashboard'"
            >
              <router-link
                class="nav-link text-primary relativeLink"
                to="/dashboard"
              >
                <i
                  class="
                    fad
                    fa-tachometer-alt
                    nav-icon-sized
                    d-none d-md-inline
                  "
                ></i>
                <span class="d-md-none">Dashboard</span>
              </router-link>
            </li>

            <li
              class="nav-item my-auto mr-4"
              v-if="user"
              v-tooltip.bottom="'Appointments'"
            >
              <router-link
                class="nav-link text-primary relativeLink"
                to="/appointments"
              >
                <i
                  class="fad fa-calendar-star nav-icon-sized d-none d-md-inline"
                ></i>
                <span class="d-md-none">Appointments</span>
                <span
                  class="badge badge-danger badge-top-right"
                  v-if="$store.unreadCounters.appointments > 0"
                  >!</span
                >
              </router-link>
            </li>

            <li
              class="nav-item my-auto mr-4"
              v-if="user"
              v-tooltip.bottom="'Orders'"
            >
              <router-link
                class="nav-link text-primary relativeLink"
                to="/orders"
              >
                <i class="fad fa-list nav-icon-sized d-none d-md-inline"></i>
                <span class="d-md-none">Orders</span>
                <span
                  class="badge badge-danger badge-top-right"
                  v-if="$store.unreadCounters.orders > 0"
                  >!</span
                >
              </router-link>
            </li>

            <li
              class="nav-item my-auto mr-4"
              v-if="user"
              v-tooltip.bottom="'Forms'"
            >
              <router-link
                class="nav-link text-primary relativeLink"
                to="/forms"
              >
                <i
                  class="
                    fad
                    fa-clipboard-list
                    nav-icon-sized
                    d-none d-md-inline
                  "
                ></i>
                <span class="d-md-none">Forms</span>
                <span
                  class="badge badge-danger badge-top-right"
                  v-if="$store.unreadCounters.forms > 0"
                  >!</span
                >
              </router-link>
            </li>

            <li
              class="nav-item my-auto mr-4"
              v-if="user"
              v-tooltip.bottom="'T&Cs'"
            >
              <router-link
                class="nav-link text-primary relativeLink"
                to="/agreements"
              >
                <i
                  class="fad fa-handshake-alt nav-icon-sized d-none d-md-inline"
                />
                <span class="d-md-none">T&Cs</span>
                <div
                  class="card-counter menu"
                  v-if="pendingAgreementsCount > 0"
                >
                  <p>{{ pendingAgreementsCount }}</p>
                </div>
              </router-link>
            </li>

            <li
              class="nav-item my-auto mr-4"
              v-if="user"
              v-tooltip.bottom="'Resources'"
            >
              <router-link
                class="nav-link text-primary relativeLink"
                to="/resources"
              >
                <i
                  class="fad fa-paperclip nav-icon-sized d-none d-md-inline"
                ></i>
                <span class="d-md-none">Resources</span>
                <span
                  class="badge badge-danger badge-top-right"
                  v-if="$store.unreadCounters.resources > 0"
                  >!</span
                >
              </router-link>
            </li>

            <li
              class="nav-item my-auto mr-4"
              v-if="user"
              v-tooltip.bottom="'Files'"
            >
              <router-link
                class="nav-link text-primary relativeLink"
                to="/files"
              >
                <i class="fad fa-folders nav-icon-sized d-none d-md-inline"></i>
                <span class="d-md-none">Files</span>
                <div
                  class="card-counter menu"
                  v-if="$store.unreadCounters.files > 0"
                >
                  <p>{{ $store.unreadCounters.files }}</p>
                </div>
              </router-link>
            </li>

            <li
              class="nav-item my-auto mr-4"
              v-if="user"
              v-tooltip.bottom="'Settings'"
            >
              <router-link
                class="nav-link text-primary relativeLink"
                to="/settings"
              >
                <i
                  class="
                    fad
                    fa-fa-sliders-h-square
                    nav-icon-sized
                    d-none d-md-inline
                  "
                ></i>
                <span class="d-md-none">Settings</span>
              </router-link>
            </li>
            <li
              class="nav-item my-auto mr-4"
              v-if="user"
              v-tooltip.bottom="'Logout'"
              @click="logout()"
            >
              <a class="nav-link text-primary" href="#">
                <i
                  class="
                    fad
                    fa-fa-sliders-h-square
                    nav-icon-sized
                    d-none d-md-inline
                  "
                ></i>
                <span class="d-md-none">Logout</span>
              </a>
            </li>
          </ul>

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ml-auto d-none d-md-flex my-auto">
            <!-- Authentication Links -->

            <li class="nav-item my-auto mr-4" v-if="!user">
              <router-link class="nav-btn btn btn-primary" to="/login"
                ><span class="text-white">Login</span></router-link
              >
            </li>

            <li class="nav-item my-auto dropdown mx-4" v-if="user">
              <a
                id="navbarDropdown"
                class="dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>{{ user.name }}</span>
                <span class="caret"></span>
              </a>

              <div
                class="dropdown-menu dropdown-menu-right mt-2"
                style="z-index: 1021"
                aria-labelledby="navbarDropdown"
              >
                <router-link class="dropdown-item" to="/settings"
                  >Settings</router-link
                >
                <div class="dropdown-divider"></div>
                <!-- <router-link
                    class="dropdown-item"
                    to="/billing"
                    v-if="user && $can('manage billing')"
                >Billing</router-link>
                <div
                    class="dropdown-divider"
                    v-if="user && $can('manage billing')"
                ></div> -->
                <a
                  class="dropdown-item"
                  href="https://support.swandoola.com/faqs-client"
                  target="_blank"
                  >FAQs</a
                >
                <a
                  class="dropdown-item"
                  href="https://swandoola.com/privacy"
                  target="_blank"
                  >Privacy</a
                >
                <a
                  class="dropdown-item"
                  href="https://swandoola.com/terms"
                  target="_blank"
                  >Terms</a
                >
                <div class="dropdown-divider"></div>
                <button
                  type="button"
                  class="dropdown-item"
                  @click="logout()"
                  v-if="user"
                >
                  Logout
                </button>
              </div>
            </li>

            <li
              class="nav-item my-auto"
              v-tooltip.bottom="'Notifications'"
              v-if="user"
            >
              <button
                @click="showNotifications"
                class="nav-link text-primary notification-pill"
              >
                <i class="fas fa-bell text-primary"></i>
                <span
                  v-if="notificationsCount > 0"
                  class="ml-2"
                  :class="
                    notificationsCount > 0
                      ? 'badge badge-danger text-white'
                      : ''
                  "
                  >{{ notificationsCount }}</span
                >
              </button>
            </li>

            <support-dropdown
              :user="user"
              @showSupportRequestModal="showSupport = true"
            ></support-dropdown>
          </ul>
        </div>
      </div>
    </nav>
    <support-request-modal
      v-if="user && showSupport"
      :user="user"
      @close="showSupport = false"
    ></support-request-modal>
  </div>
</template>

<script>
import SupportRequestModal from "../globals/SupportRequestModal";
import SupportDropdown from "./main-nav-partials/SupportDropdown";

export default {
  data() {
    return {
      notificationsCount: 0,
      showSupport: false,
    };
  },

  computed: {
    pendingAgreementsCount() {
      return this.$store.pendingAgreementsCount;
    },
    user() {
      return this.$store.user;
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  methods: {
    fetchUnreadCounters() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/unread-counters")
        .then(({ data }) => {
          this.$store.unreadCounters = data;
        });
    },
    fetchAgreements() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/agreements")
        .then(({ data }) => {
          this.$store.agreements = data.agreements;
          this.$store.pendingAgreementsCount = data.agreements.filter(
            (obj) => obj.client_responded === 0
          ).length;
        });
    },
    logout() {
      localStorage.removeItem("user");
      if (localStorage.getItem("googleLogin")) {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
          console.log("User signed out.");
        });
      }
      localStorage.removeItem("googleLogin");
      location.href = "/";
    },
    fetchNotificationsCount() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/notifications/unread")
        .then(({ data }) => {
          this.notificationsCount = data.notifications.length;
        });
    },
    goToCart() {
      this.$router.push("/cart");
    },
    showNotifications() {
      this.$EventBus.$emit("showNotifications");
    },
  },
  mounted() {
    if (this.user) {
      // this.fetchNotificationsCount();

      this.fetchUnreadCounters();
      this.fetchAgreements();
    }

    var _this = this;

    this.$EventBus.$on("notificationsRead", function () {
      _this.notificationsCount = 0;
    });
  },
  filters: {
    acronym(name) {
      var str = name;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON

      return acronym;
    },
  },
  components: {
    SupportDropdown,
    SupportRequestModal,
  },
};
</script>

<style lang="scss">
.relativeLink .badge-top-right {
  top: 25px;
  right: auto;
}
.card-counter.menu {
  right: auto;
  top: 24px;
  width: 18px;
  height: 18px;
  font-size: 75%;
  font-weight: bold;
  display: inline-block;
}
.card-counter {
  background-color: #e3342f;
  color: white;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  text-align: center;
  font-weight: bold;
}
.relativeLink {
  position: relative;
}
.navbar-light .navbar-nav .nav-link {
  color: #03006b;
}

.notification-pill {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 99px;
  width: 4.5rem;
  height: auto;
  background-color: #f4f6f6;
  color: #495a5a;
  font-weight: 600;
  font-size: 0.8rem;
}

.nav-icon-sized {
  font-size: 1.5rem;
}

.notification-pill {
  font-size: 0.75rem;
}

.notification-pill i {
  font-size: 1rem;
}
</style>
